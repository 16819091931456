import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

import { BsLayoutSidebarReverse, BsViewStacked } from 'react-icons/bs'
import { Card, Checkbox, Layout, Page, Spinner } from '@shopify/polaris'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import { Stack, TextField } from '@shopify/polaris'

import { AgGridReact } from 'ag-grid-react'
// import LineChart from './LineChart'
import _ from 'lodash'
import { apiGet } from '../../Api'
import axios from 'axios'
import moment from 'moment'
import { useCookies } from 'react-cookie'
import { useLocalCache } from '../../Components/LocalCache'

const AxsEventBrowser = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [rowData, setRowData] = useState([])
    const [filteredRowData, setFilteredRowData] = useState([])
    const [quickFilterText, setQuickFilterText] = useState('')
    const [selectedEvent, setSelectedEvent] = useState(null)
    const navigate = useNavigate()

    //const [localData, setLocalData] = useLocalCache('AxsEventBrowser', null)

    useEffect(() => {
        async function fetchData() {
            console.log('fetching data')
            const response = await apiGet('/axs/allEvents')
            //console.log('event data:', response[0])
            setRowData(response)
            setIsLoading(false)
        }

        fetchData()
    }, [])

    useEffect(() => {
        const filteredRows = rowData.filter((row) =>
            JSON.stringify(row)
                .toLowerCase()
                .includes(quickFilterText.toLowerCase())
        )
        setFilteredRowData(filteredRows)
    }, [rowData, quickFilterText])

    const handleNavigation = (path) => {
        window.open(path, '_blank', 'noopener,noreferrer')
    }

    useEffect(() => {
        console.log('selected event changed to', selectedEvent)
        if (selectedEvent) {
            //navigate('/axs/event/' + selectedEvent.id)
            handleNavigation('/axs/event/' + selectedEvent.id)
        }
    }, [navigate, selectedEvent])

    const columnDefs = [
        {
            headerName: 'ID',
            field: 'id',
            sortable: true,
            filter: true,
            width: 150
        },
        {
            headerName: 'Event Title',
            field: 'eventTitle',
            sortable: true,
            width: 300,
            filter: true
        },
        {
            headerName: 'Venue Title ',
            field: 'venueTitle',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Seat Data Count',
            field: 'seatDataCount',
            sortable: true,
            filter: true
        },
        /*{
            headerName: 'Section Data Count',
            field: 'sectionDataCount',
            sortable: true,
            filter: true
        },*/
        {
            headerName: 'Active',
            field: 'active',
            sortable: true,
            filter: true,
            valueFormatter: (params) => (params.value ? 'Yes' : 'No')
        },
        {
            headerName: 'Announce Date',
            field: 'announceDateTimeUTC',
            sortable: true,
            filter: true,
            valueFormatter: (params) => {
                const date = new Date(params.value)
                return (
                    date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
                )
            }
        },
        {
            headerName: 'Created UTC',
            field: 'createdUTC',
            sortable: true,
            filter: true,
            valueFormatter: (params) => {
                const date = new Date(params.value)
                return (
                    date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
                )
            }
        },
        {
            headerName: 'Door Date & Time',
            field: 'doorDateTimeUTC',
            sortable: true,
            filter: true,
            valueFormatter: (params) => {
                const date = new Date(params.value)
                return (
                    date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
                )
            }
        },
        {
            headerName: 'Onsale Date',
            field: 'onsaleDateTimeUTC',
            sortable: true,
            filter: true,
            valueFormatter: (params) => {
                const date = new Date(params.value)
                return (
                    date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
                )
            }
        },
        {
            headerName: 'Presale Start Date',
            field: 'presaleDateTimeUTC',
            sortable: true,
            filter: true,
            valueFormatter: (params) => {
                if (!params.value) return 'N/A'
                const date = new Date(params.value)
                return (
                    date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
                )
            }
        },
        {
            headerName: 'Presale End Date',
            field: 'presaleEndDateTimeUTC',
            sortable: true,
            filter: true,
            valueFormatter: (params) => {
                if (!params.value) return 'N/A'
                const date = new Date(params.value)
                return (
                    date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
                )
            }
        },
        {
            headerName: 'Last Seen UTC',
            field: 'lastSeenUTC',
            sortable: true,
            filter: true,
            valueFormatter: (params) => {
                const date = new Date(params.value)
                return (
                    date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
                )
            }
        },
        {
            headerName: 'Modified UTC',
            field: 'modifiedUTC',
            sortable: true,
            filter: true,
            valueFormatter: (params) => {
                const date = new Date(params.value)
                return (
                    date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
                )
            }
        },
        {
            headerName: 'Ticket URL',
            field: 'ticketing_ticketURL',
            cellRenderer: (params) => (
                <a
                    href={params.value}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {params.value}
                </a>
            ),
            sortable: true,
            filter: true
        },
        {
            headerName: 'Event URL',
            field: 'ticketing_eventUrl',
            cellRenderer: (params) => (
                <a
                    href={params.value}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {params.value}
                </a>
            ),
            sortable: true,
            filter: true
        },
        {
            headerName: 'Presale URL',
            field: 'ticketing_presaleURL',
            cellRenderer: (params) => {
                if (!params.value) return 'N/A'
                return (
                    <a
                        href={params.value}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {params.value}
                    </a>
                )
            },
            sortable: true,
            filter: true
        },
        {
            headerName: 'Venue ID',
            field: 'venueId',
            sortable: true,
            filter: true
        }
    ]

    return (
        <div style={{ height: '100%', width: '100%', padding: 8 }}>
            <div style={{ marginBottom: 4, padding: 4 }}>
                <Stack alignment="center">
                    <span>AXS Events</span>

                    <Stack.Item fill>
                        <TextField
                            labelHidden
                            aliign="right"
                            value={quickFilterText}
                            onChange={(t) => {
                                setQuickFilterText(t)
                            }}
                            placeholder="Quick Search"
                            autoComplete="off"
                        />
                    </Stack.Item>
                    <span
                        style={{
                            opacity: 0.3,
                            marginRight: 60,
                            marginLeft: 40
                        }}
                    >
                        Click on columns for more precise filtering and sorting
                    </span>
                </Stack>
            </div>

            {isLoading && <Spinner size="large" color="teal" />}

            {!isLoading && (
                <div
                    className="ag-theme-alpine"
                    style={{ height: '90%', width: '100%' }}
                >
                    <AgGridReact
                        rowData={filteredRowData}
                        columnDefs={columnDefs}
                        defaultColDef={{
                            resizable: true
                        }}
                        rowSelection="single"
                        onSelectionChanged={(e) => {
                            const rows = e.api.getSelectedRows()
                            if (rows.length === 1) {
                                setSelectedEvent(rows[0])
                            } else {
                                setSelectedEvent(null)
                            }
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export { AxsEventBrowser }
