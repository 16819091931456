export function assembleInventoryChartData(eventHistory) {
    const groupedByDate = eventHistory.reduce((acc, entry) => {
        const timestamp = new Date(entry.fetched_at).getTime()
        if (!acc[timestamp]) {
            acc[timestamp] = []
        }
        acc[timestamp].push(entry)
        return acc
    }, {})

    const sortedDates = Object.keys(groupedByDate)
        .map(Number)
        .sort((a, b) => a - b)

    const sectionDataMap = {}
    const allSeriesData = []

    sortedDates.forEach((timestamp) => {
        const entries = groupedByDate[timestamp]
        const sectionAmounts = {}
        let totalForAllSeries = 0

        entries.forEach((entry) => {
            const { sectionLabel, amount } = entry

            if (!sectionAmounts[sectionLabel]) {
                sectionAmounts[sectionLabel] = 0
            }
            sectionAmounts[sectionLabel] += amount
        })

        Object.keys(sectionAmounts).forEach((sectionLabel) => {
            if (!sectionDataMap[sectionLabel]) {
                sectionDataMap[sectionLabel] = []
            }

            sectionDataMap[sectionLabel].push({
                x: timestamp,
                y: sectionAmounts[sectionLabel],
                additionalInfo: {
                    label: `Section: ${sectionLabel}, Total Amount: ${sectionAmounts[sectionLabel]}`
                }
            })

            totalForAllSeries += sectionAmounts[sectionLabel]
        })

        allSeriesData.push({
            x: timestamp,
            y: totalForAllSeries,
            additionalInfo: {
                label: `Total Amount for all sections: ${totalForAllSeries}`
            }
        })
    })

    const sectionSeriesData = Object.keys(sectionDataMap).map(
        (sectionLabel) => ({
            name: sectionLabel,
            data: sectionDataMap[sectionLabel],
            tooltip: {
                valueDecimals: 2
            }
        })
    )

    sectionSeriesData.push({
        name: 'All',
        data: allSeriesData,
        tooltip: {
            valueDecimals: 2
        }
    })

    return sectionSeriesData
}


export function assemblePriceChartData(data) {
    const groupedData = {};
    const overallByPrice = {};

    data.forEach(function (item) {
        const key = `${item.sectionLabel} $${item.price}`;
        const timestamp = new Date(item.fetched_at).getTime();

        if (!groupedData[key]) {
            groupedData[key] = [];
        }
        groupedData[key].push([timestamp, item.amount]);

        if (!overallByPrice[item.price]) {
            overallByPrice[item.price] = {};
        }
        if (!overallByPrice[item.price][timestamp]) {
            overallByPrice[item.price][timestamp] = 0;
        }
        overallByPrice[item.price][timestamp] += item.amount;
    });

    const sectionSeries = Object.entries(groupedData).map(function ([key, data]) {
        return {
            name: key,
            data: data.sort(function (a, b) { return a[0] - b[0]; })
        };
    });

    const overallSeries = Object.entries(overallByPrice).map(function ([price, timeData]) {
        return {
            name: `Overall $${price}`,
            data: Object.entries(timeData).map(function ([timestamp, totalAmount]) {
                return [parseInt(timestamp), totalAmount];
            }).sort(function (a, b) { return a[0] - b[0]; })
        };
    });

    return [...sectionSeries, ...overallSeries];
}

export function assembleMinPriceChartData(data, isMinPriceChartYAxisQuantity = false) {
    const filteredData = data.filter(item => item.amount >= 2);
    const timestamps = [...new Set(filteredData.map(item => item.fetched_at))];
    const sections = {};
    filteredData.forEach(item => {
        const section = item.sectionLabel;
        if (!sections[section]) {
            sections[section] = [];
        }
        sections[section].push(item);
    });
    const seriesData = [];
    for (const [sectionLabel, items] of Object.entries(sections)) {
        const dataByTime = {};
        items.forEach(item => {
            if (!dataByTime[item.fetched_at]) {
                dataByTime[item.fetched_at] = [];
            }
            dataByTime[item.fetched_at].push(item);
        });
        const dataPoints = [];
        for (const fetched_at of timestamps) {
            const timeItems = dataByTime[fetched_at] || [];
            if (timeItems.length > 0) {
                const minPrice = Math.min(...timeItems.map(i => i.price));
                const minPriceItems = timeItems.filter(i => i.price === minPrice);
                const totalAmount = minPriceItems.reduce((sum, i) => sum + i.amount, 0);
                const xValue = new Date(fetched_at).getTime();
                const yValue = isMinPriceChartYAxisQuantity ? totalAmount : minPrice;
                const label = `Min Price: $${minPrice}, Amount: ${totalAmount}`;
                dataPoints.push({
                    x: xValue,
                    y: yValue,
                    additionalInfo: {
                        label: label
                    }
                });
            }
        }
        dataPoints.sort((a, b) => a.x - b.x);
        seriesData.push({
            name: sectionLabel,
            data: dataPoints
        });
    }
    const overallDataPoints = [];
    for (const fetched_at of timestamps) {
        const timeItems = filteredData.filter(item => item.fetched_at === fetched_at);
        if (timeItems.length > 0) {
            const overallMinPrice = Math.min(...timeItems.map(i => i.price));
            const overallMinPriceItems = timeItems.filter(i => i.price === overallMinPrice);
            const totalAmount = overallMinPriceItems.reduce((sum, i) => sum + i.amount, 0);
            const xValue = new Date(fetched_at).getTime();
            const yValue = isMinPriceChartYAxisQuantity ? totalAmount : overallMinPrice;
            const label = `Overall Min Price: $${overallMinPrice}, Total Amount: ${totalAmount}`;
            overallDataPoints.push({
                x: xValue,
                y: yValue,
                additionalInfo: {
                    label: label
                }
            });
        }
    }
    overallDataPoints.sort((a, b) => a.x - b.x);
    const overallSeriesName = isMinPriceChartYAxisQuantity ? "Overall Amount" : "Overall Min Price";
    seriesData.push({
        name: overallSeriesName,
        data: overallDataPoints
    });
    return seriesData;
}

